import AniLink from 'gatsby-plugin-transition-link/AniLink'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import GCLogo from '../images/G_Caspi-LOGO.inline.svg'
import NavigationMenu from './nav-menu'

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(3px);

  padding: 0 5vw;
  z-index: 2;
`

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1400px;
  height: 64px;

  @media screen and (min-width: 500px) {
    height: 76px;
  }

  > div {
    min-height: 100%;
    display: flex;
    align-items: center;

    a {
      display: flex;
      position: relative;
      align-items: center;
      text-decoration: none;
      height: 100%;
      padding: 0 2vw;
      font-size: 1rem;
      color: currentColor;
      transition: all 0.4s ease;

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background: transparent;
        transition: all 0.3s ease;
      }
      &.active::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background: #f4bc0b;
      }
      &:not(.active):hover::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background: rgba(244, 188, 11, 0.35);
      }
    }
  }
`

const Header = ({ siteTitle, location }) => (
  <HeaderWrapper>
    <HeaderInner>
      <h1 className="logo__wrapper">
        <AniLink to="/" cover duration={1} direction="left" bg="#f4bc0b">
          <GCLogo
            className="logo"
            style={{
              margin: '0.6rem 0 0',
            }}
          />
        </AniLink>
      </h1>
      <NavigationMenu currentPage={location.pathname} />
    </HeaderInner>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
