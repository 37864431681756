/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import Footer from './footer'
import Header from './header'
// import Archive from './archive'
import PortfolioListing from './listing-portfolio'
import './layout.scss'

import { FOOTER_HEIGHT } from './footer'

const sectionSlantHeight = '8vh'

const OuterWrapper = styled.section`
  display: block;
  position: relative;
  width: 100%;
`

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min(75ch, 100%) 1fr;
  padding-bottom: 1.5rem;

  > * {
    grid-column: 2;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .full-bleed {
    width: 100%;
    grid-column: 1 / 4;
  }
`

const ContentWrapper = styled.span`
  display: block;
  min-height: calc(100vh - ${FOOTER_HEIGHT * 2}px);
`

const HomeHero = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1400px;
  min-height: 35vh;
  margin: 0 auto;
  padding: 0 1rem;

  .hero__img {
    flex: 1 1 320px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > div {
      width: 100%;
    }

    img {
      margin: 0;
    }
  }

  .hero__title {
    flex: 1 1 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 0;

    h1 {
      font-size: clamp(2.6rem, 4.6rem, 6.5vw);
      line-height: calc(1ex / 0.42);
      text-align: center;

      @media screen and (min-width: 550px) {
        text-align: left;
      }
    }

    span {
      color: #f4bc0b;
    }

    @media screen and (min-width: 550px) {
      span {
        display: block;
      }
    }
  }
`

const IntroCopy = styled.h3`
  text-align: center;
  max-width: 800px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  line-height: calc(1ex / 0.32);
  font-size: 1.5rem;

  @media screen and (min-width: 600px) {
    font-size: 1.8rem;
  }
`

const PortfolioListingContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  padding: 2rem 1rem 3rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1.5rem;
`

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { regex: "/Dexter-lg/" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const onHomepage = location.pathname === '/'
  // const onBlogPost = location.pathname && location.pathname.includes('posts')

  const headerSpring = useSpring({
    from: { opacity: 0.5 },
    to: { opacity: 1 },
  })
  const contentSpring = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, 50px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  })

  return (
    <>
      <ContentWrapper>
        <Header siteTitle={data.site.siteMetadata.title} location={location} />
        <main>
          {onHomepage ? (
            <>
              <animated.div
                className="header-container"
                style={{
                  clipPath: `polygon(0% 0%, 100% 0%, 100% calc(100% - ${sectionSlantHeight}), 0% 100%)`,
                  background: 'white',
                  ...headerSpring,
                }}
              >
                <OuterWrapper>
                  <InnerWrapper style={{ padding: '0' }}>
                    <HomeHero className="full-bleed">
                      <div className="hero__title">
                        <h1>
                          Web Developer{' '}
                          <span>
                            <i>&</i>
                          </span>{' '}
                          Designer
                        </h1>
                      </div>
                      <div className="hero__img">
                        <GatsbyImage
                          image={data.file.childImageSharp.gatsbyImageData}
                          alt="Dexter the Dachshund illustration by Gideon Caspi"
                        />
                      </div>
                    </HomeHero>
                  </InnerWrapper>
                </OuterWrapper>
              </animated.div>
              <OuterWrapper>
                <InnerWrapper>
                  <IntroCopy>
                    Hi, I'm Gideon, a <strong>front-end web developer</strong>{' '}
                    and <strong>designer</strong> - yes, we do exist!
                    <br />
                    I've <strong>illustrated</strong> my way through life and
                    studied <strong>animation</strong> at university, and have
                    done a lot since&hellip;
                    <br />
                    <AniLink
                      cover
                      to="/about/"
                      duration={1}
                      bg="#1ba39c"
                      direction="right"
                      className="ghost-btn"
                    >
                      More about me &rarr;
                    </AniLink>
                  </IntroCopy>
                </InnerWrapper>
              </OuterWrapper>
              <OuterWrapper
                style={{
                  background: 'linear-gradient(15deg, #d7e7e6 70%, #a1d0cd)',
                  paddingTop: sectionSlantHeight,
                  clipPath: `polygon(0% 0%, 100% ${sectionSlantHeight}, 100% 100%, 0% 100%)`,
                }}
              >
                <InnerWrapper>
                  <h2 className="home-title--level-2">Latest Work</h2>
                  <PortfolioListingContainer className="full-bleed">
                    <PortfolioListing />
                  </PortfolioListingContainer>
                </InnerWrapper>
              </OuterWrapper>
              <OuterWrapper className="home-blog-posts__wrapper">
                <InnerWrapper className="home-blog-posts">
                  <div>{children}</div>
                  {/* <Archive /> */}
                </InnerWrapper>
              </OuterWrapper>
            </>
          ) : (
            <animated.div className="header-container" style={contentSpring}>
              <InnerWrapper>
                {children}
                {/* {onHomepage && <Archive />} */}
              </InnerWrapper>
            </animated.div>
          )}
          <span className="background-waves"></span>
        </main>
      </ContentWrapper>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {},
}

export default Layout
