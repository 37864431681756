import React from 'react'

import { TransitionState } from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import './nav-menu.scss'

const NavigationMenu = (props) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const handleKeyPress = (evt) => {
    const enterKeyCode = 13
    const spaceKeyCode = 32
    evt.persist()

    if (
      evt.target.classList.contains('toggle') &&
      (evt.keyCode === enterKeyCode || evt.keyCode === spaceKeyCode)
    ) {
      evt.preventDefault()
      setMenuOpen(!menuOpen)
    }
  }

  return (
    <nav className="header--nav">
      <TransitionState>
        {({ transitionStatus }) => {
          return (
            <>
              <button
                className={`menu-toggle${
                  menuOpen || transitionStatus === 'exiting' ? ' active' : ''
                }`}
                title="navigation menu"
                onClick={() => setMenuOpen(!menuOpen)}
                onKeyDown={(evt) => handleKeyPress(evt)}
                aria-expanded={menuOpen}
              >
                <span className="hamburger__bar"></span>
              </button>
              <div
                role="button"
                tabIndex="0"
                className={`menu-overlay menu-overlay__toggle${
                  menuOpen || transitionStatus === 'exiting' ? ' open' : ''
                }`}
                onClick={() => setMenuOpen(false)}
                onKeyDown={(evt) => handleKeyPress(evt)}
              >
                <AniLink
                  cover
                  duration={1.5}
                  bg="#f4bc0b"
                  direction="up"
                  className={`menu__link${
                    props.currentPage === '/' ? ' active' : ''
                  }`}
                  to="/"
                >
                  Home
                </AniLink>
                <AniLink
                  cover
                  duration={1.5}
                  bg="#f4bc0b"
                  direction="up"
                  className={`menu__link${
                    props.currentPage === '/about/' ? ' active' : ''
                  }`}
                  to="/about/"
                >
                  About Me
                </AniLink>
                <AniLink
                  cover
                  duration={1.5}
                  bg="#f4bc0b"
                  direction="up"
                  className={`menu__link${
                    props.currentPage === '/contact/' ? ' active' : ''
                  }`}
                  to="/contact/"
                >
                  Contact
                </AniLink>
              </div>
            </>
          )
        }}
      </TransitionState>
    </nav>
  )
}

export default NavigationMenu
