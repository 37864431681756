import React from 'react'
import styled from 'styled-components'

import Dribbble from '../images/dribbble.inline.svg'
import Instagram from '../images/instagram.inline.svg'
import Linkedin from '../images/linkedin.inline.svg'
import Twitter from '../images/twitter.inline.svg'

export const FOOTER_HEIGHT = 60

const InnerWrapper = styled.div`
  max-width: 1400px;
  width: 90%;
  padding: 2rem 0 1rem;
  margin: 0 auto;

  &.home-blog-posts {
    max-width: 800px;
    margin: 0 auto;
  }
`

const FooterWrap = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  background: #333;
  color: white;

  > div {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
  }

  a {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid;
  }
`

const SocialLinksList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    display: inline-block;
    list-style-type: none;
    margin: 0;
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`

const SocialLink = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: ${(props) => (props.colour ? props.colour : '#333')};
  background: white;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid #eee;
  transition: all 0.3s ease;

  svg {
    vertical-align: middle;
    width: 24px;
    margin-right: 8px;
    fill: ${(props) => (props.colour ? props.colour : '#555')};
    transition: fill 0.3s ease;
  }

  @media (hover: hover) {
    &:hover {
      color: white;
      background: ${(props) => (props.colour ? props.colour : '#555')};
      border: 1px solid ${(props) => (props.colour ? props.colour : '#555')};

      svg {
        fill: white;
      }
    }
  }
`

const Footer = () => (
  <>
    <SocialLinksList>
      <li>
        <SocialLink
          colour="#0077b7"
          href="https://www.linkedin.com/in/gideon-caspi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin /> LinkedIn
        </SocialLink>
      </li>
      <li>
        <SocialLink
          colour="#f05e95"
          href="https://www.dribbble.com/gideon-caspi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Dribbble /> Dribbble
        </SocialLink>
      </li>
      <li>
        <SocialLink
          colour="#e4405f"
          href="https://www.instagram.com/gideoncaspi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram /> Instagram
        </SocialLink>
      </li>
      <li>
        <SocialLink
          colour="#1da1f2"
          href="https://www.twitter.com/gideoncaspi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter /> Twitter
        </SocialLink>
      </li>
    </SocialLinksList>
    <FooterWrap>
      <InnerWrapper>
        <small>
          © Gideon Caspi {new Date().getFullYear()}. Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </small>
      </InnerWrapper>
    </FooterWrap>
  </>
)

export default Footer
