import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const PORTFOLIO_QUERY = graphql`
  query PortfolioListing {
    allMarkdownRemark(
      limit: 5
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/Portfolio/.*.md$/" } }
    ) {
      edges {
        node {
          rawMarkdownBody
          frontmatter {
            title
            slug
            date
            thumbnail
            type
            role
          }
        }
      }
    }
  }
`

const Post = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  // max-width: 300px;
  text-align: center;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgba(25, 17, 34, 0.1);
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease;

  &:hover {
    transform: translate3d(0, -2px, 0);
  }

  > a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .thumb {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;

    img {
      margin-bottom: 0.8rem;
      border-radius: 0.3rem;
    }
  }

  .meta {
    display: inline-block;
    justify-self: end;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    font-size: 0.8rem;

    &.type {
      color: #888;
    }
  }
`

const PortfolioListing = () => (
  <>
    <StaticQuery
      query={PORTFOLIO_QUERY}
      render={({ allMarkdownRemark }) =>
        allMarkdownRemark.edges.map(({ node }) => (
          <Post key={node.frontmatter.slug}>
            <Link to={`/Portfolio/${node.frontmatter.slug}`}>
              <span className="thumb">
                <img
                  src={node.frontmatter.thumbnail}
                  alt={`${node.frontmatter.title} - portfolio piece`}
                />
              </span>
              <span className="meta">
                <h5>{node.frontmatter.title}</h5>
                <p className="type">{node.frontmatter.type}</p>
              </span>
            </Link>
          </Post>
        ))
      }
    />
  </>
)

export default PortfolioListing
